import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Section from "../components/Section"
import H1 from "../components/H1"
import Container from "../components/Container"
import panda_and_clock from "../images/panda_and_clock.svg"

const GalleriePage = () => (
  <Layout>
    <SEO title="Gallerie" />
    <Section style={{ display: "flex", flexDirection: "column" }}>
      <Container>
        <H1>Tagesablauf</H1>
        <p>
          Der Panda macht gerade ein Nickerchen.. schau doch später nochmal
          vorbei
        </p>
      </Container>

      <img
        src={panda_and_clock}
        style={{
          width: "80%",
          marginBottom: "40px",
          alignSelf: "flex-end",
        }}
      />
    </Section>
  </Layout>
)

export default GalleriePage
